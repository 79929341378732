

























































































@import '~@/assets/styles/helpers/_variables.scss';

.v-icon {
  &:hover {
    color: $colorPrimary;
  }
}

.v-list-item__content {
  padding: 0px;
}
